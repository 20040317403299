<template>
  <div class="login__wrap">
    <div class="login__head">
      <el-image class="logoImg" :src="require('@/assets/img/logo.png')"></el-image>
    </div>
    <div class="login__bg">
      <div class="login__main">

        <!-- 登录 -->
          <div class="register__box mt30">
          <div class="loginTop" style="text-align:center;font-size:20px;">欢迎登录</div>
            <div class="login__box">
              <el-form
                class="sign__main"
                ref="elForm"
                :model="form.data"
                :rules="form.rules"
                @submit.prevent="submit"
              >
                <el-form-item prop="account">
                  <el-input v-model.trim="form.data.account" maxlength="11" clearable placeholder="请输入手机号码" >
                    <img slot="prefix" style="16px;height:16px;object-fit: cover;" :src="require('@/assets/img/user.png')" />
                  </el-input>
                </el-form-item>
                <el-form-item prop="password">
                  <el-input v-model.trim="form.data.password" clearable show-password placeholder="请输入密码">
                    <img slot="prefix" style="16px;height:16px;object-fit: cover;" :src="require('@/assets/img/key.png')" />
                  </el-input>
                </el-form-item>
                <el-form-item style="margin-top:-10px;">
                  <el-checkbox v-model="checked">下次自动登录</el-checkbox>
                </el-form-item>
              </el-form>
              <el-button class="theneBtn" style="margin-bottom:20px;width: 100%;padding: 15px 40px;" type="primary" :loading="form.loading" :disabled="form.loading" @click.stop="submit">登录</el-button>
            </div>
          </div>
        
      </div>
      <div class="foot">-江西壹号农业科技有限公司技术支持-</div>
    </div>
  </div>
</template>
<script>
import {setStorage} from '@/storage/index.js'
import { REG_EXP } from '@/util'
export default {
  name: 'Login',
  data() {
    return {
      checked: true,
      form: {
        codeLoading: false,
        data: {
          account: "", // 15927563994
          password: "",
        },
        rules: {
          account: [
            { required: true, message: "请输入手机号", trigger: "change" },
            { pattern: REG_EXP.mobilePhone, message: "手机号不正确", trigger: 'blur' }
          ],
          password: [
            {  required: true, message: "请输入密码", trigger: "blur" },
            {
              min: 6,
              max: 11,
              message: "密码长度在 6 到 20 个字符",
              trigger: "blur",
            },
          ],
        }
      },
      
    }
  },
  methods: {
    // 登录
    submit() {
     this.$refs.elForm.validate((valid) => {
        if (valid) {
          let _params = {
            account: this.form.data.account,
            password: this.form.data.password,
          }
          this.$http.post('/admin/login/login', _params).then(res => {
            if(res.code === 1) {
              setStorage('token', res.data.token)
              setStorage('userName', res.data.account)
              this.$router.push('/company/account')
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },
  }
}
</script>
<style scoped lang="scss">
.mt30 {
  margin-top: 30px;
}
.login__wrap {
  width: 100%;
  height: 100vh;

  .login__head {
    height: 65px;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 0 60px;
  }
  .logoImg {
    width: 145px;
    height: 34px;
  }

  .login__bg {
    height: calc(100vh - 65px);
    background-image: url('~@/assets/img/bg.png');
    background-size:cover;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .login__main {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .login-title {
      font-size: 24px;
      font-weight: bold;
      text-align: center;
      color: #303133;
      margin-top: 0;
    }

    .login__box {
      width: 460px;
      padding: 30px 40px 40px;
      // border-radius: 10px;
      background-color: #fffefe;
      box-sizing: border-box;
      overflow: hidden;
      border: solid 1px #eee;
    }
    .theneBtn {
      background-color: $theme !important;
      border-color: $theme !important;
    }
  }

  .foot {
    margin-bottom: 40px;
    color: #7C7C7C;
  }
}
.loginTop {
  height: 50px;
  line-height: 50px;
  background: #fff;
  border-radius: 10px 10px 0 0;
  padding: 0 25px;
  text-align: right;
  cursor: pointer;
}

</style>

<style scoped>
.sign__main ::v-deep .el-input__inner {
  height: 46px;
  font-family: Microsoft YaHei;
  font-size: 16px;
  padding-left: 40px;
}
.sign__main ::v-deep .el-input__prefix {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
}
.sign__main ::v-deep .el-button {
  font-family: Microsoft YaHei;
  height: 50px;
}
.sign__main ::v-deep .el-checkbox__label {
  color: #333;
}
</style>
